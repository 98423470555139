@import '../../styles/shared.scss';

.c-product-detail {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }

  &__image {
    width: 100%;

    @media screen and (min-width: $tablet) {
      width: 50%;
    }

    background-size: cover;
    height: 350px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background-color: $secondary-color;
    }
  }

  &__information {
    padding-left: 0;
    width: 100%;
    margin-top: $space_base;

    @media screen and (min-width: $tablet) {
      margin-top: 0;
      padding-left: $space_base_2;
      width: 50%;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
