html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0;
  color: $black;
  font-family: $primary-font;
}
