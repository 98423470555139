@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Patrick+Hand&display=swap');

$primary-font: 'Lato', sans-serif;

.text {
  color: $black;
  text-decoration: none;
  font-family: $primary-font;

  &.-uppercase {
    text-transform: uppercase;
  }

  &.-underline {
    text-decoration: underline;
  }

  &.-center {
    text-align: center;
  }

  &.-header-1 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 5px;

    @media screen and (min-width: $tablet) {
      font-size: 42px;
    }
  }

  &.-header-2 {
    font-size: 28px;
    letter-spacing: 56px;

    @media screen and (min-width: $tablet) {
      font-size: 36px;
    }
  }

  &.-header-3 {
    font-size: 24px;
    letter-spacing: 56px;

    @media screen and (min-width: $tablet) {
      font-size: 30px;
    }
  }

  &.-paragraph {
    font-size: 16px;
    line-height: 36px;

    @media screen and (min-width: $tablet) {
      font-size: 20px;
    }
  }

  &.-item-menu {
    font-size: 12px;
    line-height: 36px;

    @media screen and (min-width: $desktop) {
      font-size: 15px;
    }

    @media screen and (min-width: 1300px) {
      font-size: 18px;
    }
  }

  &.-item-footer {
    font-size: 15px;
    line-height: 36px;

    @media screen and (min-width: $tablet) {
      font-size: 20px;
    }
  }

  &.-white {
    color: $white;
  }

  &.-black {
    color: $black;
  }

  &.-primary-color {
    color: $primary-color;
  }

  &.-secondary-color {
    color: $secondary-color;
  }

  &.-third-color {
    color: $third-color;
  }

  &.-bold {
    font-weight: 700;
  }
}
