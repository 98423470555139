@import '../../styles/shared.scss';

.c-footer {
  padding: $space_base_2;
  background: $third-color;
  position: relative;

  &__nav {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
    }

    nav {
      margin-right: 0;
      margin-bottom: $space_base_2;

      @media screen and (min-width: $tablet) {
        margin-right: $space_base_3;
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    &__ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {
        margin-bottom: $space_base;

        &:last-of-type {
          margin-bottom: 0;
          margin-right: 0;
          @media screen and (min-width: $tablet) {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__go-top {
    position: absolute;
    right: 0;
    top: 0;
    width: $space_base_3;
    height: 100%;
    background-color: $secondary-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &__arrow {
      width: 50px;
      margin-bottom: $space_base_2;
    }
  }
}
