@import '../../styles/shared.scss';

.c-title {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 8px;
    height: 50px;
    background-color: $secondary-color;
    left: -20px;

    @media screen and (min-width: $tablet) {
      width: 10px;
      height: 70px;
    }
  }
}
