@import '../../shared/styles/shared.scss';

.leaflet-container {
  height: 500px;
}

.l-contact {
  &__map-container {
    margin-top: $space_base_2;
  }
}
